.form label {
    font-weight: bold;
    margin-bottom: 8px;
}

.form span {
    font-weight: initial;
    display: block;
}

.form label:last-of-type {
    margin-bottom: 16px;
}

.contentBox{
    max-width: 90%;
    text-align: left;
}

.dateBoxleft{
    width: 40%;
    float: left;
}

.signatureBoxRight{
    text-align: start;
}

.bottomBox{
    margin: 25px 0 25px 0px;
}