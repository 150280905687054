.amountInput > input {
    appearance: textfield;
}

.paymentAmountLabel {
    display: block;
}

.paymentAmountLabelText {
    white-space: nowrap;
    margin-right: 8px;
}

.paymentAmountLabelRestriction {
    color: var(--color-red);
    font-size: small;
    white-space: nowrap;
}

.autopayButton button{
    background-color: #FCC515 !important;
    color: black !important;
}
