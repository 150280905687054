.errorPanel {
    border-color: var(--color-red-washed);
}

.errorText {
    color: var(--color-red);
    margin: 0;
}

.coloredSubmitButton {
    background-color: var(--color-green-washed);
    color: black;
}

.coloredCancelButton {
    background-color: var(--color-red-washed);
    color: black;
}

.coloredPrintButton {
    background-color: var(--color-white-washed);
    color: black;
}
