.amountInput > input {
    appearance: textfield;
}

.paymentAmountLabel {
    display: block;
}

.paymentAmountLabelText {
    white-space: nowrap;
    margin-right: 8px;
}

.paymentAmountLabelRestriction {
    color: var(--color-red);
    font-size: small;
    white-space: nowrap;
}

.intervalOptions{
    background-color: #FCF8D9;
    width: 72%;
    font-weight: bold;
}

.smElementStyle{
    width: 25%;
    float: left;
}

.smOptionsContainer{
     padding: 0px 0px 0px 60px
}

.labelFirstPayment{
    width: 100%;
    max-width: 300px;
    text-align: left;
    font-weight: bold;
}

.stopAutopayTollTip{
    color: #0C4C97;
    font-size: 14px;
    text-align: left;
    margin-top: -10px;
}